








































































































import { Inject } from 'inversify-props';
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { DateFormats } from '@/modules/cars/enums';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import SpotChecksService, { SpotChecksServiceS } from '../spotchecks.service';
import SpotChecksFilterService, { SpotChecksFilterServiceS } from '../spotchecks-filter.service';
import SpotCheckDetailsPopup from './spotchecks-details-popup.vue';
import { ISpotChecksPhaseAvailability } from '../models/spotchecks-document.model';

@Component({
    components: {
        LoaderWrapper,
        SpotCheckDetailsPopup,
    },
})
export default class SpotChecksTable extends Vue {
    @Inject(SpotChecksFilterServiceS) private spotChecksFilterService!: SpotChecksFilterService;
    @Inject(SpotChecksServiceS) private spotChecksService!: SpotChecksService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    private tableHeight = '0px';
    private spotchecksTableHeight = '40vh';
    public isPopupOpen: boolean = false;
    protected spotChecksDetails: ISpotChecksPhaseAvailability[] = [];
    protected selectedDataSource: string = '';
    private TOTAL_PHASES = 48;

    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.height;
            this.spotchecksTableHeight = this.spotchecksHeight;
        });
    }

    @Watch('isClosedOnly')
    onClosedOnlyChange() {
        this.scrollTop();
    }

    @Watch('documents')
    onPosChange() {
        this.scrollTop();
    }

    scrollTop() {
        const el: HTMLElement | null = document.querySelector('.locations-list');
        if (el) {
            el.scrollTo({ top: 0 });
        }
    }

    get height() {
        const el: HTMLElement | null = document.querySelector('.locations-list');
        const footer: HTMLElement | null = document.querySelector('footer');

        if (el && footer) {
            const table = el.getBoundingClientRect();
            const footerBox = footer.getBoundingClientRect();
            return `calc(100vh - ${table.top + footerBox.height + 35}px)`;
        }

        return '100vh';
    }

    get spotchecksHeight() {
        const el: HTMLElement | null = document.querySelector('.table-body');
        const footer: HTMLElement | null = document.querySelector('footer');
        if (el && footer) {
            const table = el.getBoundingClientRect();
            const footerBox = footer.getBoundingClientRect();
            return `calc(100vh - ${table.top + footerBox.height + 35}px)`;
        }

        return '45vh';
    }

    get currentCompany() {
        return this.userService.currentCompany;
    }

    get documents() {
        return this.spotChecksService.documents;
    }

    get dataSources() {
        return this.spotChecksFilterService.dataSources;
    }

    get locations() {
        if (!this.locationIds) {
            return [];
        }
        return this.locationIds.filter(location => this.spotChecksFilterService.locationIds.includes(`${location.locationId}`));
    }

    get competitors() {
        const { competitors } = this.spotChecksFilterService;
        if (competitors && this.currentCompany && competitors.some(competitor => competitor === this.currentCompany)) {
            const sortedcompetitors = competitors.filter(competitor => competitor !== this.currentCompany);
            sortedcompetitors.unshift(this.currentCompany);
            return sortedcompetitors;
        }
        return competitors;
    }

    get availableDays() {
        return this.spotChecksService.availableDays;
    }

    get notAvailableDays() {
        return this.spotChecksService.notAvailableDays;
    }

    get isClosedOnly() {
        return this.spotChecksFilterService.isClosedOnly;
    }

    get locationIds() {
        return this.spotChecksFilterService.locations;
    }

    get currentDate() {
        const { date } = this.spotChecksFilterService;
        return moment.utc(date).format(DateFormats.DefaultDate);
    }

    get dayPhases(): string[] {
        const referenceDate = moment.utc(this.currentDate).startOf('day');
        const dayPhases = [];
        // Calculate time difference based on expectedDayPhase
        for (let expectedDayPhase = 0; expectedDayPhase < this.TOTAL_PHASES; expectedDayPhase++) {
            const timeDifferenceMinutes = expectedDayPhase * 30;
            const phaseDate = referenceDate.clone().add(timeDifferenceMinutes, 'minutes');
            const formattedDate = phaseDate.format(DateFormats.HourMinuteWithColons);
            dayPhases.push(formattedDate);
        }

        return dayPhases;
    }

    get sortedDayPhases(): number[] {
        return [...Array(this.TOTAL_PHASES).keys()]
            .slice(36)
            .concat([...Array(this.TOTAL_PHASES).keys()].slice(0, 36));
    }

    localTime(phase: string): string {
        return new Date(`${this.currentDate} ${phase}Z`).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    }

    getStatus(day: string, dataSource: string, locationId: string, competitor: string) {
        const spotChecksAvailability = this.spotChecksService.getSpotChecksAvailability(dataSource, locationId, competitor, day);
        switch (spotChecksAvailability) {
            case true:
                return 'available';
            case false:
                return 'notavailable';
            default:
                return 'no-data';
        }
    }

    getAvailabileDaysCount(dataSource: string, locationId: string, competitor: string) {
        return _.get(this.availableDays, `${dataSource}.${competitor}.${locationId}`, 0);
    }

    getNotAvailabileDaysCount(dataSource: string, locationId: string, competitor: string) {
        return _.get(this.notAvailableDays, `${dataSource}.${competitor}.${locationId}`, 0);
    }

    async openDetails(day: string, dataSource: string, locationId: string, competitor: string) {
        this.spotChecksDetails = this.spotChecksService.getSpotChecksDetails(dataSource, locationId, competitor, day);
        this.selectedDataSource = dataSource;
        this.isPopupOpen = true;
    }

    closePopup() {
        this.isPopupOpen = false;
    }

    isValidCompetitor(dataSource: string, competitor: string) {
        if (dataSource === this.carsSharedService.chainName?.toLowerCase()) {
            return dataSource === competitor.toLowerCase();
        }
        if (!this.isClosedOnly) {
            return true;
        }
        if (!this.notAvailableDays[dataSource] || !this.notAvailableDays[dataSource][competitor]) {
            return false;
        }
        return Object.values(this.notAvailableDays[dataSource][competitor]).every(value => value > 0);
    }

    isValidDataSource(dataSource: string) {
        if (!this.isClosedOnly) {
            return true;
        }
        if (!this.notAvailableDays[dataSource]) {
            return false;
        }
        return Object.values(this.notAvailableDays[dataSource]).some(competitor => Object.values(competitor).some(value => value > 0));
    }

    isValidLocation(dataSource: string, locationId: string, competitor: string) {
        const notAvailabileDaysCount = this.getNotAvailabileDaysCount(dataSource, locationId, competitor);
        const availabileDaysCount = this.getAvailabileDaysCount(dataSource, locationId, competitor);
        const valid = (notAvailabileDaysCount && !availabileDaysCount);
        return this.isClosedOnly ? valid : true;
    }
}


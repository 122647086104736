


















import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Header from '@/modules/common/components/header.vue';
import Sidebar from '@/modules/common/components/sidebar.vue';
import ChatButton from '@/modules/zendesk-chat/components/chat-button.vue';
import CarsSidebar from '@/modules/cars/components/cars-sidebar.vue';
import CarsHeader from '@/modules/cars/components/cars-header.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import WalkmeWidget from '@/modules/walkme/components/walkme-widget.vue';
import JiraChatButton from '@/modules/jira-chat/components/chat-button.vue';

@Component({
    components: {
        Header, Sidebar, CarsSidebar, CarsHeader, WalkmeWidget, ChatButton, JiraChatButton,
    },
})
export default class PageLayout extends Vue {
    @Inject(UserServiceS) private userService!: UserService;

    get isCarUser() {
        return this.userService.isCarUser;
    }
}

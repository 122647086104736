




























import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import HorizontalArrows from '@/modules/common/components/ui-kit/horizontal-arrows.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import AvailabilityTooltip from '@/modules/cars/components/availability-tooltip.vue';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CustomDatePicker from '@/modules/common/components/ui-kit/custom-datepicker.vue';
import SpotChecksActions from '../components/spotchecks-actions.vue';
import SpotChecksService, { SpotChecksServiceS } from '../spotchecks.service';
import SpotChecksTable from '../components/spotchecks-table.vue';
import SpotChecksStore from '../store/spotchecks.store';

@Component({
    components: {
        PageWrapper,
        CarHeader,
        AvailabilityTooltip,
        SpotChecksActions,
        SpotChecksTable,
        HorizontalArrows,
        CustomDatePicker,
    },
})
export default class SpotChecksPage extends Vue {
    @Inject(UserServiceS) userService!: UserService;
    @Inject(SpotChecksServiceS) private spotChecksService!: SpotChecksService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly storeState: SpotChecksStore = this.storeFacade.getState('SpotChecksStore');

    set selectedDate(value: Date) {
        if (value) {
            this.storeState.settings.date = value.toDateString();
        }
    }

    get selectedDate(): Date {
        const { date } = this.storeState.settings;
        return date ? new Date(date) : new Date();
    }

    isDisabledDate(date: Date) {
        const utcDate = moment.utc(date).startOf('day');
        const todayDate = moment.utc();
        return utcDate.isSameOrAfter(todayDate.startOf('day')) || utcDate.isBefore(todayDate.subtract(10, 'days'));
    }

    changeDay(diff: number) {
        const current = new Date(this.selectedDate);
        current.setDate(current.getDate() + diff);
        if (!this.isDisabledDate(current)) {
            this.selectedDate = current;
        }
    }
}

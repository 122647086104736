export interface ICustomSelectValue {
    name: string;
    value: string;
}

export interface IAvailablePosAndLocationIds {
    [pos: string]: string[];
}

export default class SpotChecksSettingsModel {
    country: string | null = null;
    locationIds: string[] = [];
    dataSources: string[] = [];
    competitors: string[] = [];
    pos: string | null = null;
    lor: number | null = null;
    date: string = new Date().toDateString();
    isClosedOnly: boolean = false;
    availablePosAndLocationIds: IAvailablePosAndLocationIds = { };
}






















































































import WeekdayLetter from '@/modules/cluster/filters/weekend-letter.filter';
import Day from '@/modules/common/types/day.type';
import { Inject } from 'inversify-props';
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import LocationAvailabilityService, { LocationAvailabilityServiceS } from '../location-availability.service';

@Component({
    filters: {
        WeekdayLetter,
    },
    components: {
        LoaderWrapper,
    },
})
export default class LocationAvailabilityTable extends Vue {
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @Inject(LocationAvailabilityServiceS) private lAvailabilityService!: LocationAvailabilityService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;

    private tableHeight = '0px';

    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.height;
        });
    }

    @Watch('isClosedOnly')
    onClosedOnlyChange() {
        this.scrollTop();
    }

    @Watch('documents')
    onPosChange() {
        this.scrollTop();
    }

    scrollTop() {
        const el: HTMLElement | null = document.querySelector('.locations-list');
        if (el) {
            el.scrollTo({ top: 0 });
        }
    }

    get height() {
        const el: HTMLElement | null = document.querySelector('.locations-list');
        const footer: HTMLElement | null = document.querySelector('footer');

        if (el && footer) {
            const table = el.getBoundingClientRect();
            const footerBox = footer.getBoundingClientRect();
            return `calc(100vh - ${table.top + footerBox.height + 35}px)`;
        }

        return '100vh';
    }

    get currentCompany() {
        return this.userService.currentCompany;
    }

    get documents() {
        return this.lAvailabilityService.documents;
    }

    get locations() {
        const { providers, documents, pickUpCityCodes } = this;
        if (!providers || !documents || !pickUpCityCodes) {
            return [];
        }

        const { currentPickupLocations } = this.lAvailabilityFiltersService;

        return currentPickupLocations.filter(location => Object.keys(this.documents!).includes(location.value)
                && this.pickUpCityCodes!.includes(location.value)
                && (!this.isClosedOnly || providers.some(provider => this.isAvailableProvider(location.value, provider))));
    }

    get providers() {
        const { providers, pos, country } = this.lAvailabilityFiltersService;
        const filteredProviders = this.carsFiltersService.getFilteredProviders(providers, pos, country);
        if (filteredProviders && this.currentCompany && filteredProviders.some(provider => provider === this.currentCompany)) {
            const sortedProviders = filteredProviders.filter(provider => provider !== this.currentCompany);
            sortedProviders.unshift(this.currentCompany);
            return sortedProviders;
        }
        return filteredProviders;
    }

    get monthDays() {
        return this.documentFiltersService.days;
    }

    get closedDays() {
        return this.lAvailabilityService.closedDays;
    }

    get unAvailableDays() {
        return this.lAvailabilityService.unAvailableDays;
    }

    get isClosedOnly() {
        return this.lAvailabilityFiltersService.isClosedOnly;
    }

    get pickUpCityCodes() {
        return this.lAvailabilityFiltersService.pickUpCityCodes;
    }

    isAvailableProvider(location: string, provider: string) {
        const unAvailableDaysCount = this.getUnAvailableDaysCount(location, provider);
        const closedDaysCount = this.getClosedDaysCount(location, provider);
        const isAllClosed = closedDaysCount === this.monthDays.length;
        const subDocument = _.get(this.documents, `${location}.pickupDates`);
        const subDocumentDays = subDocument ? Object.keys(subDocument).length : this.monthDays.length;
        const isAllUnAvailable = unAvailableDaysCount === subDocumentDays;
        return (!this.isClosedOnly || (this.isClosedOnly && isAllClosed)) && !isAllUnAvailable;
    }

    isMonday(day: Day) {
        return this.date(day).getDay() === 1;
    }

    isSunday(day: Day) {
        return this.date(day).getDay() === 0;
    }

    isToday(day: Day) {
        return this.documentFiltersService.isCurrentDay(day);
    }

    date(day: Day) {
        const { month, year } = this.documentFiltersService;
        return new Date(year, month, day);
    }

    getStatus(day: Day, locationId: string, provider: string) {
        const locationsAvailability = this.lAvailabilityService.getLocationsAvailability(locationId, provider, String(day));

        if (locationsAvailability === false) {
            return 'available';
        }
        if (locationsAvailability === true) {
            return 'unavailable';
        }

        return 'no-data';
    }

    getClosedDaysCount(locationId: string, provider: string) {
        return _.get(this.closedDays, `${locationId}.${provider}`, 0);
    }

    getUnAvailableDaysCount(locationId: string, provider: string) {
        return _.get(this.unAvailableDays, `${locationId}.${provider}`, 0);
    }
}


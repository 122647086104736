import { Inject, injectable } from 'inversify-props';
import _ from 'lodash';
import moment from 'moment';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import SpotChecksApiService, { SpotChecksApiServiceS } from './spotchecks-api.service';
import SpotChecksStore, { ISpotCheckAvailabilityCount, ISpotCheckDocument } from './store/spotchecks.store';
import SpotChecksDocumentModel, { ISpotCheckRequestFilter, ISpotChecksPhaseAvailability } from './models/spotchecks-document.model';

export const SpotChecksServiceS = Symbol.for('SpotChecksServiceS');

@injectable(SpotChecksServiceS as unknown as string)
export default class SpotChecksService {
    @Inject(SpotChecksApiServiceS) private spotChecksApiService!: SpotChecksApiService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: SpotChecksStore = this.storeFacade.getState('SpotChecksStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.filtersReady,
        ], this.init.bind(this));
    }

    init() {
        this.storeFacade.watch(() => [
            this.storeState.settings.date,
            this.storeState.settings.locationIds,
            this.storeState.settings.dataSources,
            this.storeState.settings.pos,
            this.storeState.filtersReady,
        ], this.resetLoading.bind(this));

        this.storeFacade.watch(() => [
            this.storeState.settings.competitors,
        ], this.calculateAvailabilityDays.bind(this));
    }

    resetLoading() {
        if (this.storeState.filtersReady) {
            this.storeState.loading.reset();
        }
    }

    async loadData(): Promise<boolean> {
        const { date, locationIds, dataSources, pos } = this.storeState.settings;
        if (!date || !locationIds.length || !dataSources.length || !pos) {
            this.storeState.loading.finish();
            return false;
        }
        const filter: ISpotCheckRequestFilter = {
            date: moment.utc(date).toISOString(),
            pos,
            location_ids: locationIds,
            providers: dataSources,
        };
        const data = await this.spotChecksApiService.getSpotChecksDocs(filter);

        const documents: ISpotCheckDocument = {};

        data.forEach((doc: SpotChecksDocumentModel) => {
            documents[doc.provider] = { ...documents[doc.provider], [doc.locationId]: doc };
        });

        this.storeState.documents = documents;
        this.calculateAvailabilityDays();

        return true;
    }

    get documents() {
        if (this.storeState.filtersReady) {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        }
        return this.storeState.documents;
    }

    get availableDays() {
        return this.storeState.availableDays;
    }

    get notAvailableDays() {
        return this.storeState.notAvailableDays;
    }

    calculateAvailabilityDays() {
        const availableDays: ISpotCheckAvailabilityCount = {};
        const notAvailableDays: ISpotCheckAvailabilityCount = {};
        const documents = this.documents || {};

        Object.keys(documents).forEach(dataSource => {
            availableDays[dataSource] = {};
            notAvailableDays[dataSource] = {};

            Object.keys(documents[dataSource]).forEach(locationId => {
                const { availability } = documents[dataSource][locationId];

                if (availability) {
                    Object.keys(availability).forEach(competitor => {
                        if (!availableDays[dataSource][competitor]) {
                            availableDays[dataSource][competitor] = {};
                        }
                        if (!notAvailableDays[dataSource][competitor]) {
                            notAvailableDays[dataSource][competitor] = {};
                        }

                        if (!availableDays[dataSource][competitor][locationId]) {
                            availableDays[dataSource][competitor][locationId] = 0;
                        }
                        if (!notAvailableDays[dataSource][competitor][locationId]) {
                            notAvailableDays[dataSource][competitor][locationId] = 0;
                        }

                        Object.keys(availability[competitor]).forEach(day => {
                            const dayStatus = this.getSpotChecksAvailability(dataSource, locationId, competitor, day);

                            if (dayStatus === true) {
                                availableDays[dataSource][competitor][locationId] += 1;
                            } else if (dayStatus === false) {
                                notAvailableDays[dataSource][competitor][locationId] += 1;
                            }
                        });
                    });
                }
            });
        });

        this.storeState.availableDays = availableDays;
        this.storeState.notAvailableDays = notAvailableDays;
    }

    getSpotChecksAvailability(dataSource: string, locationId: string, competitor: string, day: string): boolean | null {
        const key = `${dataSource}.${locationId}.availability.${competitor}.${day}`;
        const availabilityData = _.get(this.documents, key, null) as ISpotChecksPhaseAvailability[] | null;
        if (availabilityData === null) {
            return null;
        }
        return availabilityData.some(value => value.av === true);
    }

    getSpotChecksDetails(dataSource: string, locationId: string, competitor: string, day: string): ISpotChecksPhaseAvailability[] {
        const key = `${dataSource}.${locationId}.availability.${competitor}.${day}`;
        return _.orderBy(_.get(this.documents, key, []), ['pick_up_date_time']) as ISpotChecksPhaseAvailability[];
    }

    async getAvailablePosAndLocationIds() {
        return this.spotChecksApiService.getAvailablePosAndLocationIds();
    }
}

import { plainToClass } from 'class-transformer';
import { Inject, injectable } from 'inversify-props';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import SpotChecksDocumentModel, { ISpotCheckRequestFilter } from './models/spotchecks-document.model';

export const SpotChecksApiServiceS = Symbol.for('SpotChecksApiServiceS');
@injectable(SpotChecksApiServiceS as unknown as string)
export default class SpotChecksApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getSpotChecksDocs(filter: ISpotCheckRequestFilter) {
        const { data } = await this.apiService.post('spot-check/get-spotcheck-ui-data', filter);
        return plainToClass(SpotChecksDocumentModel, <SpotChecksDocumentModel[]> data, { excludeExtraneousValues: true });
    }

    async getAvailablePosAndLocationIds() {
        const { data } = await this.apiService.get('spot-check/available-pos-and-locations');
        return data;
    }
}












import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SpotChecksFilterService, { SpotChecksFilterServiceS } from '../../spotchecks-filter.service';

@Component({
    components: { CustomSelect },
})
export default class SpotChecksPosFilter extends Vue {
    @Inject(SpotChecksFilterServiceS) private spotChecksFilterService!: SpotChecksFilterService;

    get items(): Item[] {
        const { availablePos } = this.spotChecksFilterService;
        return availablePos.map(value => ({
            name: value,
            value,
        }));
    }

    get currentValue() {
        return this.spotChecksFilterService.pos;
    }

    set currentValue(value) {
        if (value) {
            this.spotChecksFilterService.storeState.settings.pos = value;
        }
    }
}

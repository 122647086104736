








import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import JiraService, { JiraServiceS } from '../jira.service';

@Component
export default class JiraChatButton extends Vue {
    @Inject(JiraServiceS) private jiraService!: JiraService;

    private isEnabled: boolean = false;

    mounted() {
        setTimeout(() => {
            this.isEnabled = this.jiraService.isChatEnabled();
            if (this.isEnabled) {
                this.jiraService.initChatButton('jira-chat-button');
            }
        }, 5000);
    }

    openJiraChat() {
        this.jiraService.openChat();
    }
}

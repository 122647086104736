

















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import _ from 'lodash';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import SpotChecksFilterService, { SpotChecksFilterServiceS } from '../../spotchecks-filter.service';

@Component({
    components: { CustomMultiSelect, StyledSearchbar },
})
export default class SpotChecksPickUpCityFilter extends Vue {
    @Inject(SpotChecksFilterServiceS) private spotChecksFilterService!: SpotChecksFilterService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    get query() {
        return this.spotChecksFilterService.locationQuery;
    }

    set query(value) {
        this.spotChecksFilterService.locationQuery = value;
        this.currentValue = this.items.filter(item => !item.disabled);
    }

    get items() {
        const { country, pos } = this.spotChecksFilterService;
        if (!country || !pos) {
            return [];
        }

        let locations = this.spotChecksFilterService.filterSpotChecksLocationIds(country);

        if (!locations) {
            return [];
        }

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            locations = locations.filter(location => searchPattern.test(location.locationName.toLowerCase()));
        }

        const result = locations.map(loc => ({
            name: loc.locationName,
            value: loc.locationId,
            disabled: false,
        })).sort((a, b) => {
            if (a.name <= b.name) {
                return -1;
            }
            return 1;
        });

        if (!result.length) {
            result.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        return result;
    }

    get currentValue() {
        return this.carsSharedService.isLocationsFilterLoaded() && this.spotChecksFilterService.storeState.settings.locationIds
            ? this.spotChecksFilterService.storeState.settings.locationIds
                .map(locationId => {
                    const loc = this.carsSharedService.getLocationNameById(locationId) || { locationId: '', locationName: '' };
                    return {
                        value: loc.locationId || '',
                        name: loc.locationName,
                    };
                })
            : [];
    }

    set currentValue(values) {
        this.carsSharedService.savePickUpCitiesAndCountry(values.map(loc => loc!.value), this.$route.path);
    }

    get searchDefaultValue() {
        return this.spotChecksFilterService.locationQuery;
    }

    handleChange(query: string) {
        this.query = query;
    }
}

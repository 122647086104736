import { Inject, injectable } from 'inversify-props';
import _ from 'lodash';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import SpotChecksStore from './store/spotchecks.store';
import CarsSharedService, { CarsSharedServiceS } from '../../cars-shared.service';
import CarsFiltersService, { CarsFiltersServiceS } from '../../cars-filters.service';
import SpotChecksService, { SpotChecksServiceS } from './spotchecks.service';

export const SpotChecksFilterServiceS = Symbol.for('SpotChecksFilterServiceS');
@injectable(SpotChecksFilterServiceS as unknown as string)
export default class SpotChecksFilterService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(SpotChecksServiceS) private spotChecksService!: SpotChecksService;

    readonly storeState: SpotChecksStore = this.storeFacade.getState('SpotChecksStore');

    constructor() {
        this.initFilters()
            .then(() => {
                // Update filters on document load
                this.storeFacade.watch(() => this.storeState.documents, async () => {
                    // Document can be NULL if we reset filters.
                    if (this.storeState.documents) {
                        this.refreshFilters();
                    }
                });

                this.storeFacade.watch(() => this.storeState.settings.country, () => {
                    if (this.storeState.filtersReady) {
                        this.initPos();
                        this.updateLocations(false);
                    }
                });

                this.storeFacade.watch(() => this.storeState.settings.pos, () => {
                    if (this.storeState.filtersReady) {
                        // prevent incorrect selected values from other pages
                        this.initPos();
                        this.updateLocations(false);
                    }
                });

                // Mark filters as ready
                this.storeState.filtersReady = true;
            });
    }

    async initFilters() {
        await this.initSpotChecksLocationIds();
        await this.initCountries();
        await this.initPos();
        await this.initLor();
        await this.initDataSources();
        this.updateLocations(false);
    }

    refreshFilters() {
        this.refreshCompetitors();
    }

    async initCountries() {
        const currentCountry = this.storeState.settings.country;
        if (!currentCountry) {
            [this.storeState.settings.country] = this.countryNames;
        }
    }

    async initPos() {
        const { availablePos } = this;
        if (!this.storeState.settings.pos || !availablePos.find(pos => pos === this.storeState.settings.pos)) {
            const [defaultPos] = availablePos;
            this.storeState.settings.pos = defaultPos;
        }
    }

    async initSpotChecksLocationIds() {
        const { availablePosAndLocationIds } = this.storeState.settings;
        if (!availablePosAndLocationIds?.length) {
            this.storeState.settings.availablePosAndLocationIds = await this.spotChecksService.getAvailablePosAndLocationIds();
        }
    }

    filterSpotChecksLocationIds(country: string | null) {
        const { availableLocationIds } = this;
        const locations = country ? this.carsFiltersService.filterPickUpCitiesByCountry(country) : [];
        return locations.filter(location => availableLocationIds.includes(location.locationId));
    }

    updateLocations(isByUserSelection?: boolean, updateValues: boolean = true) {
        const { country } = this.storeState.settings;
        const locations = this.filterSpotChecksLocationIds(country);
        const locationIds = locations.map(loc => loc.locationId);
        if (updateValues) {
            if (isByUserSelection) {
                this.carsSharedService.savePickUpCitiesAndCountry(locationIds, 'spotchecks');
            } else {
                this.storeState.settings.locationIds = locationIds;
            }
        }
    }

    async initLor() {
        this.storeState.settings.lor = 1;
    }

    async initDataSources() {
        const { spotchecksDataSources } = this.carsSharedService.filters;
        if (spotchecksDataSources && spotchecksDataSources.length) {
            this.storeState.settings.dataSources = spotchecksDataSources.map(item => item.toLowerCase());
        }
    }

    refreshCompetitors() {
        this.storeState.settings.competitors = this.competitorsList;
    }

    get carFiltersSettings() {
        return this.carsSharedService.filters;
    }

    get locations() {
        const { country } = this.storeState.settings;
        return country ? this.filterSpotChecksLocationIds(country) : [];
    }

    get dataSources() {
        return this.storeState.settings.dataSources;
    }

    get availablePos() {
        const { locations } = this;
        const locationIds = locations.map(location => location.locationId);
        const { availablePosAndLocationIds } = this.storeState.settings;
        return Object.keys(availablePosAndLocationIds).filter(pos => availablePosAndLocationIds[pos].some(location => locationIds.includes(location)));
    }

    get competitorsList() {
        const { documents } = this.storeState;
        const { allowedBrands, dataSources, posProviderHidden } = this.carsSharedService.filters;
        const { locationAvailabilityPos } = this.carsSharedService;
        let competitors: string[] = [];
        if (documents) {
            Object.keys(documents).forEach((dataSource: string) => {
                Object.keys(documents[dataSource]).forEach(locationId => {
                    if (documents[dataSource][locationId].availability) {
                        const allProvider = Object.keys(documents[dataSource][locationId].availability);
                        competitors = competitors.concat(allProvider);
                    }
                });
            });
            competitors = Array.from(new Set(competitors));
        }
        const availableProviders = _.union(allowedBrands, dataSources, [(this.carsSharedService.chainName || 'Brand').toLowerCase()]);
        competitors = _.intersection(availableProviders, competitors);
        const list = locationAvailabilityPos && posProviderHidden[locationAvailabilityPos]
            ? competitors.filter(competitors => !posProviderHidden[locationAvailabilityPos].includes(competitors)) : competitors;
        this.storeState.settings.competitors = list;
        return list;
    }

    get competitors() {
        return this.storeState.settings.competitors;
    }

    set competitors(competitors: string[]) {
        this.storeState.settings.competitors = competitors;
    }

    get isClosedOnly() {
        return this.storeState.settings.isClosedOnly;
    }

    set isClosedOnly(value) {
        this.storeState.settings.isClosedOnly = value;
    }

    get pos() {
        return this.storeState.settings.pos;
    }

    set pos(pos: string | null) {
        this.storeState.settings.pos = pos;
    }

    get locationIds() {
        return this.storeState.settings.locationIds;
    }

    get date() {
        return this.storeState.settings.date;
    }

    get countryNames(): string[] {
        const { countries } = this.carFiltersSettings;

        if (!countries) {
            return [];
        }

        const countryNames = new Set(Object.keys(countries));

        return Array.from(countryNames)
            .filter(country => this.filterSpotChecksLocationIds(country).length > 0);
    }

    get country() {
        return this.storeState.settings.country;
    }

    set country(country: string | null) {
        this.storeState.settings.country = country;
    }

    get locationQuery() {
        return this.storeState.locationQuery;
    }

    set locationQuery(value) {
        this.storeState.locationQuery = value;
    }

    get availableLocationIds(): string[] {
        const { availablePosAndLocationIds } = this.storeState.settings;
        return _.flatten(Object.values(availablePosAndLocationIds));
    }
}

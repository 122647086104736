

















import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import LocationAvailabilityToggle from '../../location-availability/components/location-availability-toggle.vue';
import SpotChecksFilterService, { SpotChecksFilterServiceS } from '../spotchecks-filter.service';
import { ICustomSelectValue } from '../models/spotchecks-settings.model';
import SpotChecksCompetitorsFilter from './actions/spotchecks-competitors-filter.vue';
import SpotChecksPosFilter from './actions/spotchecks-pos-filter.vue';
import SpotChecksDataSourceFilter from './actions/spotchecks-datasource-filter.vue';
import SpotChecksCountryFilter from './actions/spotchecks-country-filter.vue';
import SpotChecksPickUpCityFilter from './actions/spotchecks-locations-filter.vue';

@Component({
    components: {
        CustomSelect,
        LocationAvailabilityToggle,
        SpotChecksCompetitorsFilter,
        SpotChecksPosFilter,
        SpotChecksDataSourceFilter,
        SpotChecksCountryFilter,
        SpotChecksPickUpCityFilter,
    },
})
export default class SpotChecksActions extends Vue {
    @Inject(SpotChecksFilterServiceS) protected spotChecksFilterService!: SpotChecksFilterService;

    protected lok: string = '1';
    protected competitors: ICustomSelectValue | null = null;

    get getLokList() {
        return [{
            name: '1',
            value: '1',
        }];
    }

    get customStyle() {
        return {
            minWidth: '160px',
        };
    }
}
